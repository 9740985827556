import React, { useState, useEffect } from "react";
import { Link,graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import EmailSignUp from "../components/emailSignUp/EmailSignUp";
import CtaCard from '../components/ctaCard/CtaCard'
import Footer from "../components/footer/Footer"
import CategoriesList from "../components/categoriesList/CategoriesList"
import Nav from "../components/Nav"
import Seo from "../components/seo"
import Shopping from '../images/online-shop.png'
import * as styles from "./index.module.css"


const IndexPage = ({ data }) => {
  const categoriesData = data.allJson.categories
  console.log(categoriesData)
  return(

  <div>
    <Seo title="Home" />
    <Helmet>


    <link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css"></link>
    </Helmet>
    <Nav/>
  
    <div className={styles.headerBg }>
      <div className={styles.headerContainer}>
        <div className={styles.headerTextContainer}>
          <h1 className={styles.headerTitle}> DTC resources delivered to your inbox</h1>
          <h1 className={styles.headerSubTitle}>  A curated collection of resources for DTC merchants.</h1>
            <EmailSignUp/>

        </div>
        <div className={styles.headerImageContainer}>
          <img src={Shopping}></img>
        </div>
      </div>

    </div>
    <div>
      <div className={styles.headerContainer}>
      <CategoriesList categoriesData={categoriesData} />
      </div>
    </div>
    <CtaCard/>
    <Footer/>
  </div>
)}

export default IndexPage

export const query = graphql`
query{
	allJson{
    categories: group(field:category){
      fieldValue
      nodes{
        subcategory
        id
        fields{
          slug
        }
      }
    }
    
  }

}
`
