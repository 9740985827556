import React from "react"
import {Link} from 'gatsby'
import CategoriesCard from "../categoriesCard/CategoriesCard"
import * as styles from "./CategoriesList.module.css"

const CategoriesList = ({ categoriesData}) => {
  // change data so that the category & data template are linked by newly created ID from parent. Not order in array

  return (
    <div className={styles.sectionContainer}>
      {categoriesData.map((data,i) => {
        return (
         
            <CategoriesCard
              key={data.fieldValue}
              category={data.fieldValue}
              icon={data.icon}
              subcategories={data.nodes}
        
          />

        )
      })}
    </div>
  )
}

export default CategoriesList
