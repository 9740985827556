import React from "react"
import { Link } from "gatsby"
import * as styles from "./CategoriesCard.module.css"

const categoriesCard = ({ category, icon,subcategories }) => {
  console.log(icon)
  return (
    <Link  className={styles.content} to={subcategories[0].fields.slug.match(/([a-z-]*\/){3}/g)[0]}>
      <div>
        <h2 className={styles.contentTitle}>{category}</h2>
        <div>{icon}</div>
        <div className={styles.contentBodyContainer}>

        {subcategories.map(data =>{
          return (
            <div className={styles.contentBody} key={data.id}>{data.subcategory}</div>

          )
        })}
        </div>
      </div>
      <button className={styles.contentButton}>Go ⟶</button>
    </Link>
  )
}

export default categoriesCard
